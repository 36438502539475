import React from "react"
import { useLayoutContext } from "~layout/contexts/layout"
import Page from "~common/components/Page"
import { Box } from "~common/components/Primitives"
import ProductCard from "~shop/components/ProductCard"

const ShopPage = () => {
  const { headerHeight, availableHeight } = useLayoutContext()

  return (
    <Page
      seo={{ title: "Shop", description: "Create your personal Almagesto" }}
    >
      <Page.Body
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "repeat(2, 1fr)"],
          gridGap: 5,
          mt: headerHeight,
          px: [4, 5, 6, "9%", "16%"],
          py: [4, 5, 6],
          minHeight: availableHeight,
        }}
      >
        <ProductCard initialProductId="custom_poster_50x70cm">
          <ProductCard.Header />
          <ProductCard.Selector
            productsMap={{
              custom_poster_30x40cm: "small",
              custom_poster_50x70cm: "medium",
              custom_poster_61x91cm: "large",
            }}
          />
          <ProductCard.Image />
          <ProductCard.Description />
          <Box sx={{ mx: [4, 5] }}>
            <Box as="ul">
              <ProductCard.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING
              </ProductCard.Feature>
              <ProductCard.Feature icon="ruler">
                <ProductCard.Size />
              </ProductCard.Feature>
              <ProductCard.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductCard.Feature>
              <ProductCard.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductCard.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [3, 4] }}>
              <ProductCard.Note>no frame included</ProductCard.Note>
              <ProductCard.Note>
                graphics rendered for purely illustrative purpose
              </ProductCard.Note>
            </Box>
          </Box>
          <ProductCard.Cta />
        </ProductCard>
        <ProductCard initialProductId="newtonian_apple">
          <ProductCard.Header />
          <ProductCard.Image />
          <ProductCard.Description />
          <Box sx={{ mx: [4, 5] }}>
            <Box as="ul">
              <ProductCard.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING
              </ProductCard.Feature>
              <ProductCard.Feature icon="ruler">
                <ProductCard.Size />
              </ProductCard.Feature>
              <ProductCard.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductCard.Feature>
              <ProductCard.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductCard.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [3, 4] }}>
              <ProductCard.Note>no frame included</ProductCard.Note>
              <ProductCard.Note>
                graphics rendered for purely illustrative purpose
              </ProductCard.Note>
            </Box>
          </Box>
          <ProductCard.Cta />
        </ProductCard>
        <ProductCard initialProductId="copernican_revolution">
          <ProductCard.Header />
          <ProductCard.Image />
          <ProductCard.Description />
          <Box sx={{ mx: [4, 5] }}>
            <Box as="ul">
              <ProductCard.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING
              </ProductCard.Feature>
              <ProductCard.Feature icon="ruler">
                <ProductCard.Size />
              </ProductCard.Feature>
              <ProductCard.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductCard.Feature>
              <ProductCard.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductCard.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [3, 4] }}>
              <ProductCard.Note>no frame included</ProductCard.Note>
              <ProductCard.Note>
                graphics rendered for purely illustrative purpose
              </ProductCard.Note>
            </Box>
          </Box>
          <ProductCard.Cta />
        </ProductCard>
        <ProductCard initialProductId="almagest_heritage">
          <ProductCard.Header />
          <ProductCard.Image />
          <ProductCard.Description />
          <Box sx={{ mx: [4, 5] }}>
            <Box as="ul">
              <ProductCard.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING
              </ProductCard.Feature>
              <ProductCard.Feature icon="ruler">
                <ProductCard.Size />
              </ProductCard.Feature>
              <ProductCard.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductCard.Feature>
              <ProductCard.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductCard.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [3, 4] }}>
              <ProductCard.Note>no frame included</ProductCard.Note>
              <ProductCard.Note>
                graphics rendered for purely illustrative purpose
              </ProductCard.Note>
            </Box>
          </Box>
          <ProductCard.Cta />
        </ProductCard>
        <ProductCard initialProductId="dune_50x70cm">
          <ProductCard.Header />
          <ProductCard.Selector
            productsMap={{
              dune_30x40cm: "small",
              dune_50x70cm: "medium",
              dune_61x91cm: "large",
            }}
          />
          <ProductCard.Image />
          <ProductCard.Description />
          <Box sx={{ mx: [4, 5] }}>
            <Box as="ul">
              <ProductCard.Feature icon="shipping">
                FREE WORLDWIDE SHIPPING
              </ProductCard.Feature>
              <ProductCard.Feature icon="ruler">
                <ProductCard.Size />
              </ProductCard.Feature>
              <ProductCard.Feature icon="paper">
                Printed on Enhanced Matte Paper
              </ProductCard.Feature>
              <ProductCard.Feature icon="eco">
                Toxin-free, Biodegradable, Water-based Ink
              </ProductCard.Feature>
            </Box>
            <Box as="ul" sx={{ mt: [3, 4] }}>
              <ProductCard.Note>no frame included</ProductCard.Note>
              <ProductCard.Note>
                graphics rendered for purely illustrative purpose
              </ProductCard.Note>
            </Box>
          </Box>
          <ProductCard.Cta />
        </ProductCard>
      </Page.Body>
    </Page>
  )
}

export default ShopPage
