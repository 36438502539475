import React from "react"
import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  provideProductContext,
  useProductContext,
} from "~shop/contexts/ProductContext"
import { Box } from "~common/components/Primitives"
import SchemaMarkup from "~common/components/SchemaMarkup"
import Typography from "~common/components/Typography"
import Icon from "~common/components/Icon"
import Button from "~common/components/Button"
import Line from "~common/components/Line"
import RadioButton from "~common/components/RadioButton"
import PriceTag from "~shop/components/PriceTag"
import { pushToGtm } from "~common/utils/gtm"

const ProductCard = provideProductContext(({ children, sx, ...rest }) => {
  const { product } = useProductContext()
  const { name, image, description, price } = product
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: theme => `1px solid ${theme.colors["softened-secondary"]}`,
        boxShadow: theme =>
          `0 0 16px 0px ${theme.colors["softened-secondary"]}`,
        ...sx,
      }}
      {...rest}
    >
      <SchemaMarkup
        data={{
          "@context": "https://schema.org/",
          "@type": "Product",
          name,
          image: image,
          description,
          brand: "Almagesto",
          offers: {
            "@type": "Offer",
            url: "/shop",
            priceCurrency: "EUR",
            price,
            availability: "https://schema.org/InStock",
          },
        }}
      />
      {children}
    </Box>
  )
})

ProductCard.Header = function ProductCardHeader({ sx, ...rest }) {
  const { product } = useProductContext()

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        mx: [4, 5],
        my: [3, 4],
        ...sx,
      }}
      {...rest}
    >
      <Typography
        as="h1"
        variant="heading2"
        sx={{
          color: "secondary",
          lineHeight: [1],
        }}
      >
        {product ? product.name : ""}
      </Typography>
      {product && (
        <PriceTag price={product.price} discount={product.discount} />
      )}
    </Box>
  )
}

ProductCard.Image = function ProductCardImage({ sx, ...rest }) {
  const { product } = useProductContext()
  const { featuredImage } = product

  return (
    <GatsbyImage
      image={featuredImage}
      alt={product ? product.description : "placeholder"}
      {...rest}
    />
  )
}

ProductCard.Description = function ProductCardDescription({ sx, ...rest }) {
  const { product } = useProductContext()

  return (
    <Typography
      variant="normal"
      sx={{
        mx: [4, 5],
        my: [3, 4],
        fontWeight: 500,
        lineHeight: [1],
        ...sx,
      }}
      {...rest}
    >
      {product ? product.description : ""}
    </Typography>
  )
}

ProductCard.Feature = ({ children, icon, sx, ...rest }) => (
  <Box sx={{ display: "flex", alignItems: "center", ...sx }} {...rest}>
    <Icon name={icon} color="secondary" sx={{ mr: [4, 5] }} />
    <Typography variant="small" sx={{ color: "secondary" }}>
      {children}
    </Typography>
  </Box>
)

ProductCard.Note = ({ children, sx, ...rest }) => (
  <Box sx={{ display: "flex", alignItems: "center", ...sx }} {...rest}>
    <Typography variant="small" sx={{ color: "softened-secondary" }}>
      <Box as="span" sx={{ mr: [3, 4] }}>
        *
      </Box>
      {children}
    </Typography>
  </Box>
)

ProductCard.Cta = function ProductCardCta({ sx, ...rest }) {
  const { product } = useProductContext()

  if (!product) return null

  const { id, name, description, image, url, price, customizable } = product

  const handleConfigurator = () => {
    pushToGtm({ event: "configurator_init", item_id: id })
    navigate(`/configurator?productId=${id}`)
  }

  return (
    <Box
      sx={{
        mx: [4, 5],
        my: [4, 5],
        display: "flex",
        justifyContent: "flex-end",
        ...sx,
      }}
      {...rest}
    >
      {customizable ? (
        <Button variant="accent" onClick={handleConfigurator}>
          Create
        </Button>
      ) : (
        <Button
          variant="accent"
          className="snipcart-add-item"
          data-item-id={id}
          data-item-price={price}
          data-item-url={url}
          data-item-description={description}
          data-item-image={image}
          data-item-name={name}
        >
          Add To Cart
        </Button>
      )}
    </Box>
  )
}

ProductCard.Size = function ProductCardSize({ sx, ...rest }) {
  const { product } = useProductContext()

  if (!product) return null

  const { size } = product

  return (
    <Box as="span" sx={{ ...sx }} {...rest}>
      {size}
    </Box>
  )
}

ProductCard.Selector = function ProductCardSelector({
  productsMap,
  sx,
  ...rest
}) {
  const { product, selectProductById } = useProductContext()

  if (!product) return null

  const handleSelect = productId => () => selectProductById(productId)

  return (
    <Box sx={{ mb: [3, 4] }}>
      <Line
        align="center"
        color="secondary"
        direction="horizontal"
        sx={{ width: "75%", mx: "auto" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: ["row"],
          justifyContent: "space-around",
          mx: [4, 5],
          ...sx,
        }}
        {...rest}
      >
        {Object.keys(productsMap).map((productId, i) => {
          const productName = productsMap[productId]
          const selected = product.id === productId

          return (
            <RadioButton
              key={i}
              selected={selected}
              onClick={handleSelect(productId)}
              sx={{
                my: [3],
              }}
            >
              <RadioButton.Bullet />
              <RadioButton.Text>{productName.toUpperCase()}</RadioButton.Text>
            </RadioButton>
          )
        })}
      </Box>
      <Line
        align="center"
        color="secondary"
        direction="horizontal"
        sx={{ width: "75%", mx: "auto" }}
      />
    </Box>
  )
}

export default ProductCard
